import { useEffect, useState } from "react";
import ScrollView from "./ScrollView";
import Note from "../models/Note";

export default function EvolEleve({ notes = [], children = undefined }) {
  const [sortedNotes, setSortedNotes] = useState();
  const [selectedNote, setSelectedNote] = useState();

  useEffect(() => {
    const sortedNotes = notes.map((n) => new Note(n)).sort(Note.sort);
    setSortedNotes(sortedNotes);
    setSelectedNote();
  }, [notes]);

  function changeSelectedNote(e, note) {
    e.preventDefault();
    if (note._id !== selectedNote?._id) {
      setSelectedNote(note);
    } else {
      setSelectedNote();
    }
  }

  function chooseColor(note) {
    if (note?.rapportClasse > 0) {
      return "label label-success";
    } else  if (note?.rapportClasse < 0) {
      return "label label-error";
    } else {
      return "label";
    }
  }

  return (
    <div style={{ border: "1px solid gray", marginTop: "5px" }}>
      <ScrollView>
        <div className="d-flex">
          {sortedNotes &&
            sortedNotes.map((note, i) => (
              <div key={i} className={selectedNote?._id === note._id ? "noteItemActive" : "noteItem"} onClick={(e) => changeSelectedNote(e, note)}>
                <span>{note.date}</span>
                <div><span className={chooseColor(note)}>{note.noteEleve20 ?? "N"}</span></div>
              </div>
            ))}
        </div>
      </ScrollView>
      {selectedNote && (
        <div className="container">
          <div className="columns">
            <div className="column col">
              <span className="label label-secondary">{selectedNote?.nomFull}</span>
            </div>
          </div>
          <div className="columns">
            <div className="column col">
              <span className="titreDevoir">note: </span>
              {selectedNote?.noteEleve20}
            </div>
            <div className="column col">
              <span className="titreDevoir">moyenne classe: </span>
              {selectedNote?.moyenneClasse}
            </div>
            <div className="column col">
              <span className="titreDevoir">coeff: </span>
              {selectedNote?.coeff}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
