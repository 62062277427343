export default function ListElement({
  elements = [],
  children = undefined,
  canDelete = false,
  selectedElement = undefined,
  selectedElementChange = undefined,
  isSelected = (e, selectedElement) => e._id === selectedElement?._id,
  onDelete = undefined,
}) {

  async function changeSelectedElement(e, element) {
    e.preventDefault();
    selectedElementChange?.(element);
  }

  function calculElementListClassname(e) {
    var className = ["menu-item"];
    if (isSelected(e, selectedElement)) {
      className.push("border-menu");
    }
    return className.join(" ");
  }
  
  return (
    <ul className="menu">
      {
        elements.map((element, i) => (
          <li key={i} onClick={(e) => changeSelectedElement(e, element)} className={calculElementListClassname(element)}>
            <div className="container">
              <div className="columns">
                <div className="column col">
                  {children(element)}
                </div>
                {canDelete && (<div className="column col-auto">
                  <button className="btn btn-sm btn-error input-group-btn s-circle" onClick={() => onDelete?.(element)}>
                    <i className="icon icon-cross"></i>
                  </button>
                </div>)}
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  );
}
