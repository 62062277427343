import "../../styles/Admin.css";
import Devoir from "../../models/Devoir";
import AdminTemplate from "./AdminTemplate";
import Select from "../Select";
import List from "../List";
import ListSelect from "../ListSelect";
import DevoirItem from "../Devoirs/DevoirItem";
import Accordion from "../Accordion";
import ScrollView from "../ScrollView";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllEleves, selectAllClasses, getEleves } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs, addDevoir } from "../../store/devoirs";
import { selectAllGroupes, getGroupes } from "../../store/groupes";
import { clone } from "../../utils/fonctions";

var today = new Date();
const dateStr = today.getFullYear() - 2000 + (today.getMonth() + 1).toString().padStart(2, "0") + today.getDate().toString().padStart(2, "0");

export default function AdminDevoirs() {
  const [selectedDevoir, setSelectedDevoir] = useState();
  const [forceCopie, setForceCopie] = useState(false);
  const [dateCopieDevoir, setDateCopieDevoir] = useState(dateStr);
  const [classeCopieDevoir, setClasseCopieDevoir] = useState("");
  const [modalCopieDocumentActive, setModalCopieDocumentActive] = useState(false);

  const dispatch = useDispatch();
  const devoirs = useSelector(selectAllDevoirs);
  const groupes = useSelector(selectAllGroupes);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const devoirsStatus = useSelector((state) => state.devoirs.status);
  const groupesStatus = useSelector((state) => state.eleves.status);

  const classes = ["MP", "MPSI", "PCSI", "1"];

  useEffect(() => {
    if (groupesStatus === "idle") {
      dispatch(getGroupes());
    }
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
  }, [dispatch, elevesStatus, devoirsStatus, groupesStatus]);

  function changeSelectDevoir(devoir) {
    setSelectedDevoir(devoir);
  }

  function changeDevoir(devoir) {
    setSelectedDevoir(new Devoir(devoir));
  }

  function nouveau() {
    var devoir = new Devoir({ date: dateStr });
    devoir.classe = classeCopieDevoir;
    devoir.matiere = "SII";
    setSelectedDevoir(devoir);
  }

  function changeDateNouveauDevoir(value) {
    setDateCopieDevoir(value);
    selectedDevoir.date = value;
  }

  function changeClasseNouveauDevoir(value) {
    setClasseCopieDevoir(value);
    selectedDevoir.classe = value;
  }

  function sauvegarder(value) {
    setDateCopieDevoir(dateStr);
    setClasseCopieDevoir(selectedDevoir.classe);
    setForceCopie(false);
    setModalCopieDocumentActive(value);
  }

  function validerSauvegarde() {
    if (!dateCopieDevoir || dateCopieDevoir.length !== 6 || isNaN(parseInt(dateCopieDevoir))) {
      // erreur
    }
    const update = selectedDevoir._id !== "" && selectedDevoir.classe === classeCopieDevoir;
    const devoir = new Devoir(selectedDevoir);
    if (!update) {
      devoir.date = dateCopieDevoir;
    }
    devoir.classe = classeCopieDevoir;
    console.log(devoir);
    
    dispatch(addDevoir(devoir));
    setModalCopieDocumentActive(false);
    changeSelectDevoir("");
  }

  return (
    <AdminTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-12">
            <ListSelect
              maxHeight="calc(100vh - 180px)"
              elements={devoirs.map((d) => new Devoir(d))}
              addElement={() => nouveau(true)}
              selectedElement={selectedDevoir}
              selectedElementChange={(devoir) => changeSelectDevoir(devoir)}
              smallOnly={true}
              canFilterDeactivated={false}
              filterElement={(devoir, filter) => devoir.nom.toUpperCase().includes(filter.toUpperCase())}
              sortElement={Devoir.sort}
            >
              {(devoir) => devoir ? (
                <div>
                  <span>{devoir?.nom}</span>
                </div>
              ) : (
                <span>Choisir un devoir</span>
              )}
            </ListSelect>
          </div>
          {selectedDevoir && (
            <>
              <div className="column col-12 col-md-12 pt-2">
                <DevoirItem devoirInitial={selectedDevoir} onChange={changeDevoir}></DevoirItem>
              </div>
              <div className="column col-12 col-md-12">
                <button
                  disabled={!selectedDevoir || selectedDevoir.sujet === ""}
                  className="btn btn-primary longButton mb-2 tooltip tooltip-top"
                  data-tooltip="Sauvegarder le devoir"
                  onClick={() => sauvegarder(true)}
                >
                  Sauvegarder
                </button>
              </div>
            </>
          )}
        </div>
        <Modal title="Sauvegarder le devoir" keySuffix="copieModal" active={modalCopieDocumentActive} onCancel={() => sauvegarder(false)} onValidate={() => validerSauvegarde()}>
          <div className="container">
            <div className="columns">
              {selectedDevoir && selectedDevoir._id === "" && (
                <>
                  <div className="column col-12 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Date au format AAMMJJ
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        id="input-dateCopie"
                        placeholder="AAMMJJ"
                        value={dateCopieDevoir}
                        onChange={(e) => changeDateNouveauDevoir(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Classe
                      </label>
                      <Select
                        elements={classes}
                        select={classeCopieDevoir}
                        emptyElement={true}
                        emptyElementLabel={"Choisir une classe"}
                        onSelectChange={(c) => changeClasseNouveauDevoir(c)}
                        keySelector={(c) => c}
                        valueSelector={(c) => c}
                        labelSelector={(c) => c}
                      />
                    </div>
                  </div>
                  <div className="column col-12 mt-2">
                    Sauvegarder le nouveau devoir <span className="bg-secondary text-primary px-2">{selectedDevoir?.nom}</span>
                  </div>
                </>
              )}
              {selectedDevoir && selectedDevoir._id !== "" && (
                <>
                  <div className="column col-12 mb-2">
                    <div className="form-group">
                      <label className="form-checkbox">
                        <input type="checkbox" checked={forceCopie} onChange={() => setForceCopie(!forceCopie)} />
                        <i className="form-icon"></i>Copier le devoir sur une nouvelle date, classe
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Date au format AAMMJJ
                      </label>
                      <input
                        disabled={!forceCopie}
                        className="form-input"
                        type="text"
                        id="input-dateCopie"
                        placeholder="AAMMJJ"
                        value={dateCopieDevoir}
                        onChange={(e) => setDateCopieDevoir(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Classe
                      </label>
                      <Select
                        disabled={!forceCopie}
                        elements={classes}
                        select={classeCopieDevoir}
                        emptyElement={true}
                        emptyElementLabel={"Choisir une classe"}
                        onSelectChange={(c) => setClasseCopieDevoir(c)}
                        keySelector={(c) => c}
                        valueSelector={(c) => c}
                        labelSelector={(c) => c}
                      />
                    </div>
                  </div>
                  {forceCopie && (
                    <div className="column col-12 mt-2">
                      Copie du devoir <span className="bg-secondary text-primary px-2">{selectedDevoir?.nom}</span> pour la classe{" "}
                      <span className="bg-secondary text-primary">{classeCopieDevoir}</span> à la date du <span className="bg-secondary text-primary">{dateCopieDevoir}</span>{" "}
                    </div>
                  )}
                  {!forceCopie && (
                    <div className="column col-12 mt-2">
                      Mise à jour du devoir <span className="bg-secondary text-primary px-2">{selectedDevoir?.nom}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </AdminTemplate>
  );
}
