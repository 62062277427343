import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllDocuments, getDocuments, addDocument, getDocument, updateDocument, deleteDocument, refreshDocument } from "../store/documents";
import { clone } from "../utils/fonctions";
import Accordion from "./Accordion";
import ListElement from "./ListElement";
import Select from "./Select";

export default function Documents() {
  const [visible, setVisible] = useState(false);
  const [classes, setClasses] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [document, setDocument] = useState();
  const [documentsParDossiers, setDocumentsParDossiers] = useState(undefined);
  const documents = useSelector(selectAllDocuments);
  const documentsStatus = useSelector((state) => state.documents.status);
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  useEffect(() => {
    if (documentsStatus === "idle") {
      dispatch(getDocuments());
    }
    if (documents) {
      const docParDossiers = documents.reduce((acc, e) => {
        const directory = e.directory.toString();
        var docsDirectory = acc.filter(a => a.directory === directory).shift();
        if (!docsDirectory) {
          docsDirectory = { directory: directory, documents: [] };
          acc.push(docsDirectory);
        }
        docsDirectory.documents.push(e);
        return acc;
      }, []);
      setDocumentsParDossiers(docParDossiers);
    }
  }, [dispatch, documentsStatus, documents]);

  function changeDocument(files) {
    if (files && files.length > 0) {
      setDocument(files[0]);
    } else {
      setDocument();
    }
  }
  async function supprimeDocument(document) {
    if (!document) {
      return;
    }
    await dispatch(deleteDocument(document)).unwrap();
    dispatch(getDocuments());
  }

  async function downloadDocument(document) {
    if (!document) {
      return;
    }
    await dispatch(getDocument(document)).unwrap();
  }

  async function uploadDocument() {
    if (!document) {
      return;
    }
    await dispatch(addDocument(document)).unwrap();
    dispatch(getDocuments());
  }

  function changeDocument(document) {
    setSelectedDocument(document);
    setClasses(document.classes);
    setVisible(document.visible);
  }

  function changeVisible() {
    setVisible(!visible);
  }

  function changeClasses(target) {
    const classes = []
    for (let index = 0; index < 4; index++) {
      const element = target[index];
      if (element && element.selected) {
        classes.push(element.outerText);
      }
    }
    setClasses(classes);
  }

  async function sauvegarder() {
    const document = clone(selectedDocument)
    document.classes = classes;
    document.visible = visible;
    await dispatch(updateDocument(document)).unwrap();
    dispatch(getDocuments());
  }

  async function rafraichir() {
    setSelectedDocument();
    await dispatch(refreshDocument()).unwrap();
    dispatch(getDocuments());
  }

  return (
    <div className="columns">
      <div className="column col-6">
      {isAdmin && (<div className="m-2">
        <button className="btn btn-secondary longButton mt-2" onClick={() => rafraichir()}>
          <i className="icon icon-refresh"></i> Rafraichir 
        </button>
      </div>)}
        {documentsParDossiers && documentsParDossiers.length > 0 && (documentsParDossiers.map((directory, index) => (
          <div key={"directory" + index} className="container">
            <Accordion title={directory.directory.replace(",", " | ")} keySuffix={"directory" + index} defaultChecked={true}>
              <ListElement
                elements={directory.documents} keyPrefix={"file"} canDelete={isAdmin} onDelete={(d) => supprimeDocument(d)}
                selectedElement={selectedDocument} selectedElementChange={document => changeDocument(document)} isSelected={(e, element) => e?.id === element?.id}>
                {(d) => (
                  <div className="columns">
                    <div className="column col-auto">
                      <button className="btn btn-sm btn-primary input-group-btn s-circle" onClick={() => downloadDocument(d)}>
                        <i className="icon icon-download"></i>
                      </button>
                    </div>
                    <div className={!d.visible ? "column col text-error" : "column col"}>{d.nom}</div>
                  </div>
                )}
              </ListElement>
            </Accordion>
          </div>)
        ))}
      </div>
      {isAdmin && selectedDocument && (<div className="column col-6">
        <div className="form-group">
          <label className="form-label" forhtml="input-nom">
            Nom
          </label>
          <input className="form-input" type="text" id="input-nom" placeholder="nom" value={selectedDocument.nom} readOnly />
        </div>
        <div className="form-group">
          <label className="form-label" forhtml="input-rep">
            Repertoire
          </label>
          <input className="form-input" type="text" id="input-rep" placeholder="nom" value={selectedDocument.directory} readOnly />
        </div>
        <div className="form-group">
          <label className="form-label" forhtml="input-droit">
            Droits (classes qui peuvent voir)
          </label>
          <select className="form-select" value={classes} multiple onChange={(e) => changeClasses(e.target)} >
            <option value="1" >1</option>
            <option value="MP">MP</option>
            <option value="MPSI">MPSI</option>
            <option value="PCSI">PCSI</option>
          </select>
        </div>
        <div className="form-group">
          <label className="form-label" forhtml="input-visible">
            Visible
          </label>
          <label className="form-switch">
            <input type="checkbox" id="input-visible" checked={visible} onChange={() => changeVisible()} />
            <i className="form-icon"></i>
          </label>
        </div>
        <button className="btn btn-primary longButton mt-2" onClick={() => sauvegarder()}>
          Sauvegarder
        </button>
      </div>
      )}
    </div >
  );
}
