import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, del, put, download } from "../utils/fonctions";

const initialState = {
  documents: [],
  status: "idle",
  error: null,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    resetDocuments: (state) => {
      state.status = "idle";
      state.statusNote = "idle";
      state.documents = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDocuments.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.documents = action.payload;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { resetDocuments } = documentsSlice.actions

export const selectAllDocuments = (state) => state.documents.documents;

export const getDocuments = createAsyncThunk("get/documents", async () => {
  return await get("/api/documents");
});

export const deleteDocument = createAsyncThunk("delete/documents", async (document) => {
  return await del(`/api/documents/${document.id}`);
});

export const getDocument = createAsyncThunk("get/document", async (document) => {
  return await get(`/api/documents/${document.id}`, { responseType: "blob" } ).then((blob) => {
    download(document.nom, blob);
  });
});

export const addDocument = createAsyncThunk("posts/document", async (document) => {
  const formData = new FormData();
  formData.append(document.nom, document);
  return await post("/api/documents", formData, { "Content-Type": "multipart/form-data" });
});

export const updateDocument = createAsyncThunk("put/document", async (document) => {
  return await put(`/api/documents/${document.id}`, document);
});

export const refreshDocument = createAsyncThunk("synchro/document", async () => {
  return await get("api/synchro/documents");
});

function getDocumentTimestamp(document) {
  if (!document) {
    return;
  }
  const index = document.indexOf("_");
  if (index < 0) {
    return;
  }
  return document.substring(0, index);
}

export default documentsSlice.reducer;