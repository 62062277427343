import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Note from "../../models/Note";
import DevoirNotes from "../../models/DevoirNotes";
import QuestionNote from "../../models/QuestionNote";
import Devoir from "../../models/Devoir";
import AdminTemplate from "./AdminTemplate";
import ScrollView from "../ScrollView";
import NoteItem from "../NoteItem";
import Select from "../Select";
import List from "../List";
import ListSelect from "../ListSelect";
import Modal from "../Modal";
import EvolEleve from "../EvolEleve";
import { selectAllEleves, getEleves, getEleveNotes, addEleveNote, selectEleveNotes } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs, addDevoir } from "../../store/devoirs";
import { selectDevoirNotes, getDevoirNotes, resetDevoirNotes } from "../../store/notes";

export default function AdminEvaluation() {
  const [modalCoeffActive, setModalCoeffActive] = useState(false);
  const [modalVerrouillageActive, setModalVerrouillageActive] = useState(false);
  const [coeff, setCoeff] = useState(0);
  const [moyenne, setMoyenne] = useState(0);
  const [selectedDevoirId, setSelectedDevoirId] = useState("");
  const [selectedDevoir, setSelectedDevoir] = useState();
  const [devoirNotes, setDevoirNotes] = useState();
  const [selectedNote, setSelectedNote] = useState();
  const [devoirsEval, setDevoirsEval] = useState();
  const [sort, setSort] = useState(false);
  const [detail, setDetail] = useState(false);

  const notes = useSelector(selectEleveNotes);
  const eleves = useSelector(selectAllEleves);
  const devoirNotesRaw = useSelector(selectDevoirNotes);
  const devoirs = useSelector(selectAllDevoirs);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const devoirsStatus = useSelector((state) => state.devoirs.status);

  const dispatch = useDispatch();

  useEffect(() => {
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    if (devoirs) {
      setDevoirsEval(
        devoirs
          .map((devoir) => new Devoir(devoir))
          .filter((d) => !d.verrouille)
          .sort(Devoir.sort)
      );
    }

    if (selectedDevoir && eleves && !devoirNotes && devoirNotesRaw) {
      const devoirNotes = DevoirNotes.construire(selectedDevoir, devoirNotesRaw, eleves);
      setDevoirNotes(devoirNotes);
    }
  }, [dispatch, elevesStatus, devoirsStatus, devoirs, devoirNotesRaw, selectedDevoir, devoirNotes, eleves, sort]);

  async function changeSelectDevoir(devoirId) {
    setSelectedNote();
    setDevoirNotes();
    dispatch(resetDevoirNotes());

    const devoirRaw = devoirs.filter((d) => d._id === devoirId).shift();
    if (devoirRaw) {
      const devoir = new Devoir(devoirRaw);
      dispatch(getDevoirNotes(devoir.date));
      setSelectedDevoir(devoir);
    }
    setSelectedDevoirId(devoirId);
  }

  async function changeSelectedNote(note) {
    dispatch(getEleveNotes(note.login));
    setSelectedNote(note);
  }

  function changeNote(q, val) {
    const noteChanged = new Note(selectedNote);
    const questionChange = noteChanged.questions.filter((question) => question.numero === q.numero).shift();
    questionChange.pointEleve = val;
    questionChange.acquis = questionChange.pointEleve === questionChange.pointQuestion;
    noteChanged.noteEleve = noteChanged.questions.reduce((a, q) => (!a && !q.pointEleve && q.pointEleve !== 0 ? undefined : (a ?? 0) + (q.pointEleve ?? 0)), undefined);
    setSelectedNote(noteChanged);
  }

  function changeCoeff(coeff) {
    const modifiedDevoir = devoirNotes.changeCoeff(Number(coeff));
    setMoyenne(modifiedDevoir.moyenne);
    setCoeff(modifiedDevoir.devoir.coeff);
  }

  function changeMoyenne(moyenne) {
    const modifiedDevoir = devoirNotes.moyenneDesire(Number(moyenne));
    setCoeff(modifiedDevoir.devoir.coeff);
    setMoyenne(modifiedDevoir.moyenne);
  }

  function lanceChangementCoeff() {
    setCoeff(devoirNotes.devoir.coeff);
    setMoyenne(devoirNotes.moyenne);
    setModalCoeffActive(true);
  }

  function cancelCoeff() {
    setModalCoeffActive(false);
  }

  async function validerSauvegardeCoeff() {
    const modifiedDevoir = devoirNotes.changeCoeff(Number(coeff));
    const modifiedNote = new Note({ ...selectedNote, coeff: coeff });

    setCoeff(modifiedDevoir.devoir.coeff);
    setMoyenne(modifiedDevoir.moyenne);
    setDevoirNotes(modifiedDevoir);
    setSelectedNote(modifiedNote);
    setModalCoeffActive(false);

    await dispatch(addDevoir(modifiedDevoir.devoir)).unwrap();
    dispatch(getDevoirs());
  }

  function lanceVerrouillage() {
    setModalVerrouillageActive(true);
  }

  function cancelVerrouillage() {
    setModalVerrouillageActive(false);
  }

  async function validerSauvegardeVerrouillage() {
    const modifiedDevoir = devoirNotes.devoir.changeVerrouillage();
    setModalVerrouillageActive(false);
    changeSelectDevoir("");

    await dispatch(addDevoir(modifiedDevoir)).unwrap();
    dispatch(getDevoirs());
  }

  function changeAcquis(q) {
    const noteChanged = new Note(selectedNote);
    const questionChange = noteChanged.questions.filter((question) => question.numero === q.numero).shift();
    questionChange.acquis = !questionChange.acquis;
    setSelectedNote(noteChanged);
  }

  async function sauvegarder() {
    setSelectedNote();
    setDevoirNotes();
    dispatch(resetDevoirNotes());

    const note = new Note(selectedNote);
    await dispatch(addEleveNote(note)).unwrap();
    dispatch(getDevoirNotes(selectedDevoir.date));
  }

  return (
    <AdminTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-1 col-md-2">
            <label className="form-label" htmlFor="input-example-3">
              Devoir
            </label>
          </div>
          <div className="column col-11 col-md-10">
            <Select
              elements={devoirsEval}
              select={selectedDevoirId}
              emptyElement={true}
              emptyElementLabel={"Choisir un devoir à évaluer"}
              onSelectChange={(d) => changeSelectDevoir(d)}
              keySelector={(c) => c._id}
              valueSelector={(c) => c._id}
              labelSelector={(c) => c.nom}
            />
          </div>
          {selectedDevoirId !== "" && devoirNotes && (
            <div className="column col-4 col-md-12">
              <div className="container mt-2">
                <div className="columns">
                  <div className="column col">
                    Classe {selectedDevoir.classe} ({devoirNotes.notes.filter((n) => !n.estEvaluer).length}/{devoirNotes.notes.length})
                  </div>
                  <div className="column col-auto">
                    <div className="popover popover-bottom">
                      <span className="titreDevoir">moyenne: </span>
                      <span className={devoirNotes.devoir.coeff != 1 ? "titreModif" : ""}>{devoirNotes.moyenne}</span>
                      <div className="popover-container">
                        <div className="card">
                          <div className="card-body">
                            <span className="titreDevoir">moyenne: </span>
                            {devoirNotes.moyenne}
                            <br />
                            <span className="titreDevoir">moyenne de base: </span>
                            {devoirNotes.moyenneBase}
                            <br />
                            <span className="titreDevoir">coeff: </span>
                            {devoirNotes.devoir.coeff}
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ListSelect
                canAdd={false}
                elements={devoirNotes.notes}
                maxHeight="calc(100vh - 250px)"
                sortElement={Note.Sort}
                filterStyle="success"
                isSelected={(note, selectedNote) => note?.login === selectedNote?.login}
                filterDeactivated={(note) => !note?.estEvaluer}
                filterElement={(note, filter) => (note.nom + note.prenom).toUpperCase().includes(filter.toUpperCase())}
                selectedElement={selectedNote}
                selectedElementChange={(note) => changeSelectedNote(note)}
              >
                {(note) =>
                  note ? (
                    <div className="container">
                      <div className="columns">
                        <div className="column col-1 mt-1">
                          {note.estEvaluer && <i className="icon icon-check text-success"></i>}
                          {!note.estEvaluer && <i className="icon icon-cross text-error"></i>}
                        </div>
                        <div className="column col-9">
                          {note.nom} {note.prenom}
                        </div>
                        {note.login !== selectedNote?.login && <div className="column col-2">{(note.noteEleve20 && note.noteEleve20) >= 0 ? note.noteEleve20 : "N"}</div>}
                        {note.login === selectedNote?.login && (
                          <div className="column col-2">{(selectedNote?.noteEleve20 && selectedNote?.noteEleve20) >= 0 ? selectedNote?.noteEleve20 : "N"}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <span>Choisir un élève</span>
                  )
                }
              </ListSelect>
              <div className="container my-2">
                <div className="columns">
                  <div className="column col-6">
                    <button className="btn btn-secondary longButton" onClick={() => lanceChangementCoeff()}>
                      Changer Coeff
                    </button>
                  </div>
                  <div className="column col-6">
                    <button className="btn btn-secondary longButton" onClick={() => lanceVerrouillage()}>
                      Verrouiller
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedNote && (
            <div className="column col-8 col-md-12 mt-1">
              <div className="columns">
                <div className="column col-1">
                  <button className={"btn btn-action btn-sm" + (detail ? " active" : "")} onClick={() => setDetail(!detail)}>
                    <i className={detail ? "icon icon-minus" : "icon icon-plus"}></i>
                  </button>
                </div>
                <div className="column col-3">
                  {selectedNote?.nom} {selectedNote?.prenom}
                </div>
                <div className="column col-4">
                  <div className="popover popover-bottom">
                    <span className="titreDevoir">points: </span>
                    <span>
                      {selectedNote.noteEleve}/{selectedNote.noteDS}
                    </span>
                    <div className="popover-container">
                      <div className="card">
                        <div className="card-body">
                          <span className="titreDevoir">points: </span>
                          {selectedNote.noteEleve}/{selectedNote.noteDS}
                          <br />
                          <span className="titreDevoir">note: </span>
                          {selectedNote.noteEleve20}
                          <br />
                          <span className="titreDevoir">note de base: </span>
                          {selectedNote.noteEleve20Base}
                          <br />
                          <span className="titreDevoir">coeff: </span>
                          {selectedNote.coeff}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column col-3"></div>
                <div className="column col-1">
                  <button className={"btn btn-action btn-sm" + (sort ? " active" : "")} onClick={() => setSort(!sort)}>
                    <i className={sort ? "icon icon-upward" : "icon icon-downward"}></i>
                  </button>
                </div>
                <div className="column col-12">
                  {detail && <EvolEleve notes={notes}></EvolEleve>}
                  <ScrollView maxHeight={detail ? "calc(100vh - 320px)" : "calc(100vh - 250px)"} margin="10 0 0 0" overflowX="hidden">
                    {selectedNote?.questions
                      .slice()
                      .sort((a, b) => (sort ? -1 : 1))
                      ?.map((q, i) => (
                        <div key={i} className="columns">
                          <div className="column col-2">
                            <span className="numero tooltip tooltip-right" data-tooltip={q.origine.chapitre + " - " + q.origine.competence + " - " + q.origine.notion}>
                              {q.numero}
                            </span>
                          </div>
                          <div className="column col-auto">
                            <NoteItem note={q.pointEleve} noteMax={q.pointQuestion} onChange={(val) => changeNote(q, val)} />
                          </div>
                          <div className="column col-1">
                            <div className="form-group">
                              <label className="form-switch">
                                <input type="checkbox" checked={q.acquis} onChange={() => changeAcquis(q)} />
                                <i className="form-icon"></i>Acquis
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                  </ScrollView>
                </div>
                <div className="column col-12">
                  <button disabled={!selectedDevoir || selectedDevoir.sujet === ""} className="btn btn-primary longButton mb-2" onClick={() => sauvegarder()}>
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          )}

          <Modal
            title="Changer le coefficient du devoir"
            keySuffix="coeffModal"
            active={modalCoeffActive}
            onCancel={() => cancelCoeff()}
            onValidate={() => validerSauvegardeCoeff()}
          >
            <div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-dateCopie">
                  Coefficient
                </label>
                <input className="form-input" type="number" id="input-dateCopie" step="0.01" value={coeff} onChange={(e) => changeCoeff(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-dateCopie">
                  Moyenne
                </label>
                <input className="form-input" type="number" id="input-dateCopie" step="0.01" value={moyenne} onChange={(e) => changeMoyenne(e.target.value)} />
              </div>
            </div>
          </Modal>

          <Modal
            title="Verrouiller le devoir"
            keySuffix="verrouillageModal"
            active={modalVerrouillageActive}
            onCancel={() => cancelVerrouillage()}
            onValidate={() => validerSauvegardeVerrouillage()}
          >
            <div>
              Voulez-vous verrouiller le devoir {selectedDevoir?.nom}? <br />
              Pour le déverrouiller, vous devez passer par l'onglet "Devoir" du module d'Administation.
            </div>
          </Modal>
        </div>
      </div>
    </AdminTemplate>
  );
}
